<template>
  <div :class="'flex-wrapper-app'">
    <!-- <Alert /> -->
    <Navbar />
    <main :class="'search-main'">
      <div class="container">
        <Loader v-if="isLoading" />
        <div class="content-wrapper">
          <h1 class="search-title">Εξυπηρέτηση σχετικά με την αποστολή</h1>

          <div class="contact-info">
            <h4><span>Τηλέφωνο: </span> &nbsp; <a href="tel:2111982107">211 198 2107</a></h4>
            <h4>
              <span>Email: </span> &nbsp;
              <a href="mailto:help@skroutzdelivery.gr">help@skroutzdelivery.gr</a>
            </h4>
            <h4 class="operating-hours">
              <span>Ώρες Λειτουργίας:</span>&nbsp;
              <div class="hours-content">
                Δευτέρα - Παρασκευή: 09:00 - 20:00<br />
                Σάββατο: 09:00 - 18:00<br />
                Κυριακή: Κλειστά<br />
                <span class="disclaimer">*Εκτός επίσημων αργιών</span>
              </div>
            </h4>
          </div>
        </div>
      </div>
    </main>
    <Footer />
  </div>
</template>

<script>
import Navbar from '../pages-sections/Navbar.vue';
import Footer from '../pages-sections/Footer.vue';
import Loader from '../loader/Loader.vue';

export default {
  name: 'Home',
  components: {
    Navbar,
    Footer,
    Loader,
  },
  watch: {
    $route: 'checkUrlHash',
  },
  mounted() {
    this.checkUrlHash();
  },
  methods: {
    checkUrlHash() {
      if (window.location.hash.length > 1) {
        const trackId = window.location.hash.replace('#', '');
        this.requestTracking(trackId);
      } else {
        this.isTrackIdValid = false;
      }
    },
  },
};
</script>

<style lang="scss">
.flex-wrapper-app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.search-main {
  flex-grow: 1;
  display: flex;
  background: url('../../assets/images/graphic-bg-search-page.svg') no-repeat;
  padding: 145px 0 156px;
}

.container {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  padding: 0 20px;
}

.content-wrapper {
  max-width: 490px;
}

.search-title {
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 16px;
}

.hours-content {
  margin-top: 8px;
}

.disclaimer {
  font-weight: bold;
  font-size: 0.9em;
}

// Mobile Small (<= 480px)
@media screen and (max-width: 480px) {
  .search-main {
    padding: 40px 0 180px;
  }

  .container {
    padding: 0 16px;
  }

  .search-title {
    font-size: 18px;
  }
}

// Mobile Large (481px - 733px)
@media screen and (max-width: 733px) {
  .search-main {
    padding: 56px 0 224px;
    background-position: bottom center;
    background-size: contain;
  }

  .content-wrapper {
    min-width: 328px;
  }
}

// Tablet (734px - 960px)
@media screen and (min-width: 734px) and (max-width: 960px) {
  .search-main {
    padding: 136px 0 433px;
    background-position: bottom center;
    background-size: contain;
  }
}

// Desktop (961px - 1500px)
@media screen and (min-width: 961px) and (max-width: 1500px) {
  .search-main {
    padding: 120px 0 120px;
    background-position: 22rem 2.5rem;
  }
}

// Large Desktop (> 1500px)
@media screen and (min-width: 1501px) {
  .search-main {
    padding: 120px 0 120px;
    background-position: 72% center;
  }
}
</style>
